import { useState, useEffect, useCallback } from 'react';
import { groupBy } from 'lodash';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { deleteFile, fetchFiles, updateData } from '../../api/tabsApi';
import { axiosInstance } from '../../api/init';
import { useTabsContext } from '../tabs/useTabsContext';
import { useHandleUpload } from '../tabs/SubTabs';
import { allIcons, CirlceIcon, icons } from '../utils/Icon';
import { colors } from '../../theme';
import DocumentTable from '../tabs/DocumentTable';

const ViewByType = ({
  file,
  fileUrl,
  filesLength,
  setCurr,
}: {
  file: File;
  fileUrl: string;
  filesLength: number;
  setCurr: (fn: (nb: number) => number) => void;
}) => {
  let component;

  switch (file?.type?.replace(/^\./, '')) {
    case 'pdf':
      component = (
        <iframe src={fileUrl} width='100%' height='100%' title='PDF Viewer' />
      );
      break;
    case 'png':
    case 'jpg':
    case 'jpeg':
      component = (
        <Box boxSize='inherit'>
          <img
            className='imgViewer'
            src={fileUrl}
            alt='Image preview'
            max-width='100%'
            max-height='100%'
          />
        </Box>
      );
      break;
    case 'mp3':
      component = <audio controls src={fileUrl} />;
      break;
    case 'mp4':
      component = (
        <video controls width='100%' height='auto'>
          <source src={fileUrl} type='video/mp4' />
          Votre navigateur ne supporte pas le tag vidéo.
        </video>
      );
      break;
    case undefined:
      component = <p>Pas de fichier</p>;
      break;
    default:
      component = <p>Type de fichier non supporté.</p>;
  }

  return (
    <Flex
      alignItems='center'
      flexGrow={1}
      overflow='hidden'
      userSelect='none'
      position='relative'
    >
      {filesLength > 1 ? (
        <CirlceIcon
          as={allIcons.HiChevronLeft}
          onClick={() => setCurr((c) => (c - 1 < 0 ? filesLength - 1 : c - 1))}
        />
      ) : null}
      <Flex
        mx={3}
        flexGrow={1}
        height='75vh'
        overflow='auto'
        alignItems='center'
        justifyContent='center'
      >
        {component}
      </Flex>
      {filesLength > 1 ? (
        <CirlceIcon
          as={allIcons.HiChevronRight}
          onClick={() => setCurr((c) => (c + 1 > filesLength - 1 ? 0 : c + 1))}
        />
      ) : null}
    </Flex>
  );
};

const FileViewer = () => {
  const { rowData, documentList, getData } = useTabsContext();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleUpload = useHandleUpload();

  const [files, setFiles] = useState<(File & { _id: string })[]>([]);
  const [modalFilesId, setModalFilesId] = useState<string>('');
  const [curr, setCurr] = useState(0);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [fileUrls, setFileUrls] = useState<Record<string, string>>({});

  const groupFiles = groupBy(files, 'documentId');
  const modalFiles = groupFiles[modalFilesId] || [];

  const file = modalFiles[curr] || {};
  const fileUrl = fileUrls[file._id];

  const getFiles = useCallback(async () => {
    const resp = await fetchFiles(rowData._id);
    setFiles(resp.data);
  }, [rowData._id]);

  useEffect(() => {
    getFiles();
  }, []);

  useEffect(() => {
    if (!file?._id || fileUrl) return;

    // Récupérer l'URL du fichier depuis le serveur backend
    const fetchFile = async () => {
      try {
        const response = await axiosInstance.get(`/api/file/id/${file._id}`, {
          responseType: 'blob', // important pour recevoir les fichiers binaires
        });

        // Créer une URL blob pour l'objet reçu
        const url = URL.createObjectURL(response.data);
        setFileUrls((f) => ({ ...f, [file._id]: url }));
      } catch (error) {
        console.error('Erreur lors de la récupération du fichier:', error);
      }
    };

    fetchFile();
  }, [file?._id]);

  useEffect(() => {
    setCurr((c) => (c - 1 < 0 ? modalFiles.length - 1 : c - 1));
  }, [modalFiles.length]);

  return (
    <>
      <DocumentTable
        data={documentList}
        onView={(dataId: any) => {
          setCurr(0);
          setModalFilesId(dataId);
          onOpen();
        }}
        onUpload={async (dataId: string, file: any) => {
          await handleUpload(file, dataId);
          await getData(rowData._id);
          getFiles();
        }}
        onUpdate={async (payload: any) => {
          await updateData(payload);
          await getData(rowData._id);
        }}
        groupFiles={groupFiles}
      />
      <Modal isOpen={isOpen} onClose={onClose} size='full'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {documentList?.find((dl) => dl._id === modalFilesId)?.name}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody display='flex'>
            <ViewByType
              setCurr={setCurr}
              file={file}
              fileUrl={fileUrl}
              filesLength={modalFiles.length}
            />
          </ModalBody>
          <ModalFooter>
            {isDeleteConfirm ? (
              <>
                <Button
                  bgColor={`${colors.main}.400`}
                  onClick={async () => {
                    await deleteFile(file._id);
                    await getData(rowData._id);
                    getFiles();
                    setIsDeleteConfirm(false);
                  }}
                  mr={3}
                >
                  Confirmer
                </Button>
                <Button onClick={() => setIsDeleteConfirm(false)}>
                  Annuler
                </Button>
              </>
            ) : modalFiles.length ? (
              <CirlceIcon
                backgroundColor='red.500'
                color='white'
                cursor='pointer'
                _hover={{
                  backgroundColor: 'white',
                  color: 'red.500',
                  fontSize: '1.5rem',
                }}
                icon={icons.trash}
                onClick={() => setIsDeleteConfirm(true)}
              />
            ) : null}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FileViewer;
