import { useEffect, useMemo, useRef, useState } from 'react';
import { entries, max, sum, values } from 'lodash';
import { Box, Checkbox } from '@chakra-ui/react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedMinMaxValues,
  getFacetedUniqueValues,
  ColumnFiltersState,
  ColumnMeta,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useGlobalContext } from '../../useGlobalContext';
import { useTabsContext } from '../tabs/useTabsContext';
import { getValue, strNomalize } from '../../helpers';
import { TColumn } from './DynamicTable';
import { theme } from '../../theme';

type TTable = {
  data: any[];
  columns: TColumn[];
  onLoad?: () => void;
  editEntryKey?: string;
  hideButtons?: boolean;
  noResize?: boolean;
  onEditClick?: any;
};

const NB_WIDTH = 60;
const DEFAULT_WIDTH = 100;
export const TABLE_FONT_SIZE = theme.fontSize.table;

const computeWidths = (setMinWidths: any, nbWidth: number, isNb?: boolean) => {
  let newWidths: number[] = [];

  const elements: NodeList = document.querySelectorAll('#reactTable th');

  elements?.forEach((el: any, idx) => {
    const elWidth = Number(window.getComputedStyle(el).width.replace('px', ''));
    const widthToAdd =
      isNb && idx === 0 ? nbWidth : (max([DEFAULT_WIDTH, elWidth]) as number);
    newWidths.push(widthToAdd);
  });

  const maxWidth = getValue('#reactTableContainer', 'width');
  const diff = maxWidth - sum(newWidths) - 3;
  const eachWidth =
    diff > 0 ? diff / (isNb ? newWidths.length - 1 : newWidths.length) : 0;

  newWidths = newWidths.map((w, idx) =>
    isNb && idx === 0 ? nbWidth : w + eachWidth,
  );

  setMinWidths(newWidths);
};

const EMPTY_ARRAY: any[] = [];

const numberSort = (rowA: any, rowB: any, columnId: string, desc: string) => {
  const a = rowA.values[columnId];
  const b = rowB.values[columnId];
  if (a === b) return 0;
  if (desc) {
    return a > b ? -1 : 1;
  } else {
    return a > b ? 1 : -1;
  }
};

const useTableContainer = ({
  noResize,
  data,
  columns,
  editEntryKey,
  onLoad,
  onEditClick: oec,
}: TTable) => {
  const {
    currentTab,
    tabToUpdate,
    rowSelected,
    globalFilter,
    setRowSelected,
    setRowData,
  } = useTabsContext() || {};
  const { isHovered, isMobile, setModalType, filterActive, showNbLine } =
    useGlobalContext() || {};
  const tempFilters = useRef<ColumnFiltersState | undefined>();

  const [nbWidth, setNbWidth] = useState(NB_WIDTH);
  const [isReady, setIsReady] = useState(false);
  const [minWidths, setMinWidths] = useState<number[]>([]);
  const [finalWidths, setMaxWidths] = useState<number[]>([]);
  const [widthSize, setWidthSize] = useState(window.innerWidth);
  const [totalSize, setTotalSize] = useState(0);

  const checkAll =
    !!data.length &&
    values(rowSelected).filter((f) => f).length === data.length;

  const finalsCols: any[] = (
    showNbLine
      ? [
          {
            header: ({ table }: any) => (
              <Box cursor='default'>
                N°
                <Checkbox
                  ml={2}
                  isChecked={checkAll}
                  onChange={() => {
                    if (checkAll) {
                      setRowSelected((row) =>
                        entries(row).reduce(
                          (prev, [k]) => ({ ...prev, [k]: false }),
                          {},
                        ),
                      );
                    } else {
                      setRowSelected(
                        table.getRowModel().rows.reduce(
                          (prev: any, row: any) => ({
                            ...prev,
                            [row.original._id]: true,
                          }),
                          {},
                        ),
                      );
                    }
                  }}
                />
              </Box>
            ),
            id: 'N°',
            accessorFn: (row: any, rowIdx: number) => {
              return rowIdx + 1;
            },
            size: nbWidth,
            sortType: numberSort,
            enableSorting: false,
            meta: {
              _id: 'tableIndex',
              tableIndex: true,
            } as ColumnMeta<any, any>,
          },
          ...columns.map((col) =>
            (col.meta as any)?.type === 'number'
              ? {
                  ...col,
                  sortType: numberSort,
                }
              : col,
          ),
        ]
      : columns
  ).map((c, idx) => ({
    ...c,
    size: showNbLine && idx === 0 ? nbWidth : finalWidths[idx],
  }));

  const table = useReactTable({
    data: !isReady ? EMPTY_ARRAY : data,
    columns: columns.length ? finalsCols : [],
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    globalFilterFn: (row, columnId, filterValue) => {
      const norm = values(
        row
          .getAllCells()
          .map((cell) =>
            cell.column.id === 'N°'
              ? ''
              : strNomalize(`${cell.renderValue()}`.replace(/ /g, '')),
          ),
      ).join('__');

      return norm.includes(strNomalize(filterValue.replace(/ /g, '')));
    },
  });

  const parentRef = useRef<any>();

  const rowVirtualizer = useVirtualizer({
    count: table.getRowCount(),
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
  });

  const { getHeaderGroups, getRowModel } = table;
  const virtualRows = rowVirtualizer.getVirtualItems();
  const resizeW = getHeaderGroups()[0].headers.filter((header) =>
    header.column.getIsResizing(),
  )?.[0];

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidthSize(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    setTotalSize(
      table.getRowCount() * 35 +
        (document.querySelector('#reactTableContainer')?.getBoundingClientRect()
          .top || 0) -
        (isMobile ? 16 : 24),
    );
  }, [
    table.getRowCount(),
    document.querySelector('#reactTableContainer')?.getBoundingClientRect().top,
  ]);

  useEffect(() => {
    onLoad?.();
  }, [editEntryKey]);

  useEffect(() => {
    if (!filterActive) {
      setNbWidth(NB_WIDTH);
      tempFilters.current = table.options.state.columnFilters;
      table.setColumnFilters([]);
    } else {
      setNbWidth(110);
      table.setColumnFilters(tempFilters.current || []);
    }
  }, [filterActive]);

  useEffect(() => {
    setTimeout(() => {
      setIsReady(false);

      getHeaderGroups()[0].headers.forEach((header) => {
        header.column.resetSize();
      });

      if (finalWidths.length) {
        setMinWidths([]);
        setMaxWidths([]);
      }
    }, 5);
  }, [
    currentTab,
    tabToUpdate,
    showNbLine,
    editEntryKey,
    filterActive,
    widthSize,
    isHovered,
  ]);

  useEffect(() => {
    if (!minWidths.length && !finalWidths.length && columns.length) {
      computeWidths(setMinWidths, nbWidth, showNbLine);
    }
  }, [minWidths, finalWidths, columns]);

  useEffect(() => {
    if (minWidths.length && !finalWidths.length) {
      setMaxWidths(
        minWidths.map((w, idx) =>
          idx === 0 && showNbLine ? w : max([DEFAULT_WIDTH, w]) || 0,
        ),
      );
    }
  }, [minWidths]);

  useEffect(() => {
    if (minWidths.length && resizeW) {
      const resizeFinal = [...finalWidths];
      resizeFinal[resizeW.index] = resizeW.getSize();
      setMaxWidths(resizeFinal);
    }
  }, [resizeW]);

  useEffect(() => {
    if (finalWidths.length) {
      setIsReady(true);
    }
  }, [finalWidths, data]);

  useEffect(() => {
    table.setGlobalFilter(globalFilter);
  }, [globalFilter]);

  const onEditClick = (row: any) => {
    if (oec) {
      oec(row);
    } else {
      setModalType(editEntryKey || 'editEntry');
    }

    setRowData(row.original);
  };

  const props = useMemo(
    () => ({
      parentRef,
      table,
      totalSize,
      virtualRows,
      minWidths,
      finalWidths,
      noResize,
      isReady,
      getHeaderGroups,
      getRowModel,
      onEditClick: oec || onEditClick,
    }),
    [
      parentRef,
      table,
      totalSize,
      virtualRows,
      minWidths,
      finalWidths,
      isReady,
      noResize,
    ],
  );

  return props;
};

export type TTableContainer = ReturnType<typeof useTableContainer>;
export default useTableContainer;
